<template>
  <div class="body">
    <div class="img1">
      <img class="img1" :src="item.imagePath" />
    </div>
    <div class="information">
      <div class="title">{{ item.name }}</div>
      <div class="news">
        <div class="news-item">
          活动时间：{{ String(item.createTime).slice(0, 10) }} - {{ String(item.endTime).slice(0, 10) }}
          <div class="news-item">活动地址：{{ item.addr }}</div>
          <div class="news-item">主办方：{{ item.orgUnit_dictText }}</div>
        </div>
      </div>
    </div>
    <div class="introduce">
      <div class="top">
        <div class="text">活动介绍</div>
      </div>
      <div class="content" v-html="item.introduce"></div>
    </div>
    <div class="sign" v-if="item.meaning">
      <div class="title-box">
        <div class="title">活动意义</div>
        <img class="img4" src="~@/assets/img/park/title_bg.png" />
      </div>
      <div class="content" v-html="item.meaning"></div>
    </div>
  </div>
</template>

<script>
import { getActivityDetail } from '@/api/park'

export default {
  components: {},
  data() {
    const { id } = this.$route.params
    return {
      id,
      item: {},
      finishing: false
    }
  },
  computed: {
    role() {
      return this.type
    },
    isOrgRole() {
      return this.role === 'org' || this.role === 'employee'
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const that = this
      const { success, result } = await getActivityDetail({
        id: that.id
      })
      if (success) {
        that.item = result
      }
    }
  }
}
</script>

<style lang="less" scoped>
// @font-face {
//   font-family: 'SourceHanSansCN';
//   font-style: normal;
//   src: url('~@/assets/font/SourceHanSansCN.otf');
// }

// @font-face {
//   font-family: 'DFPSongW9-GB';
//   font-style: normal;
//   src: url('~@/assets/font/DFPSongW9-GB.ttf');
// }

.body {
  width: 100%;
  height: 100%;
  background: rgba(247, 248, 252, 1);

  .img1 {
    width: 375px;
    height: 241px;

    .share {
      position: absolute;
      width: 35px;
      height: 35px;
      top: 12px;
      right: 13px;
    }
  }

  .information {
    width: 375px;
    background: rgba(255, 255, 255, 1);
    padding-top: 17px;

    .title {
      width: 335px;
      margin-left: 15px;
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-family: 'SourceHanSansCN';
      font-size: 15.5px;
      text-align: left;
      line-height: 22.7px;
    }

    .news {
      margin: 20px 15px 0px 15px;
      padding-bottom: 10px;

      .news-item {
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        font-size: 14px;
        text-align: left;
        line-height: 30px;
      }
    }
  }

  .introduce {
    margin-top: 10px;
    width: 375px;
    background: rgba(255, 255, 255, 1);

    .top {
      width: 375px;
      height: 45.5px;

      .text {
        margin-left: 15px;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        font-family: 'SourceHanSansCN';
        position: relative;
      }

      .text::after {
        content: '';
        position: absolute;
        width: 30px;
        height: 3px;
        border-radius: 1.5px;
        background: rgba(16, 115, 227, 1);
        bottom: -10px;
        left: 13px;
      }
    }

    .img2 {
      width: 345px;
      height: 148.5px;
      margin: 16.5px 15px 0 15px;
    }

    .title-name {
      display: flex;
      align-items: center;
      margin-left: 15px;
      margin-top: 22px;

      .yellow {
        width: 4px;
        height: 13px;
        background: rgba(253, 194, 3, 1);
      }

      .name {
        font-weight: 500;
        font-size: 14.5px;
        text-align: left;
        font-family: 'SourceHanSansCN';
        margin-left: 8px;
      }
    }

    .text {
      width: 332px;
      margin-left: 19.5px;
      color: rgba(0, 0, 0, 1);
      font-weight: 400;
      font-family: 'SourceHanSansCN';
      font-size: 13px;
      text-align: justifyLeft;
      line-height: 27px;
    }

    .img3 {
      width: 339.5px;
      height: 206.5px;
      margin: 22px 15px 0 20.5px;
    }

    .content {
      padding: 15px;

      ::v-deep img {
        max-width: 100% !important;
        width: auto !important;
        height: auto !important;
      }

      ::v-deep p:has(> img) {
        text-indent: 0 !important;
        margin-left: 0 !important;
        padding-left: 0 !important;
      }
    }
  }

  .sign {
    margin-top: 10px;
    width: 375px;
    height: 361px;
    background: rgba(255, 255, 255, 1);
    padding-top: 23px;

    .title-box {
      position: relative;
      width: 78.5px;
      height: 23.5px;
      margin: 0 149.5px 0 147px;

      .title {
        position: absolute;
        font-weight: 500;
        font-size: 18px;
        text-align: justifyLeft;
        font-family: 'SourceHanSansCN';
        left: 5px;
      }

      .img4 {
        position: absolute;
        width: 78.5px;
        height: 6px;
        bottom: 0;
      }
    }

    .title-name {
      display: flex;
      align-items: center;
      margin-left: 15px;
      margin-top: 24.5px;

      .yellow {
        width: 4px;
        height: 13px;
        background: rgba(253, 194, 3, 1);
      }

      .name {
        font-weight: 500;
        font-size: 14.5px;
        text-align: left;
        font-family: 'SourceHanSansCN';
        margin-left: 8px;
      }
    }

    .img3 {
      width: 339.5px;
      height: 206.5px;
      margin: 22px 15px 0 20.5px;
    }
  }
}
</style>
