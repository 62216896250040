var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body" }, [
    _c("div", { staticClass: "img1" }, [
      _c("img", { staticClass: "img1", attrs: { src: _vm.item.imagePath } })
    ]),
    _c("div", { staticClass: "information" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.item.name))]),
      _c("div", { staticClass: "news" }, [
        _c("div", { staticClass: "news-item" }, [
          _vm._v(
            " 活动时间：" +
              _vm._s(String(_vm.item.createTime).slice(0, 10)) +
              " - " +
              _vm._s(String(_vm.item.endTime).slice(0, 10)) +
              " "
          ),
          _c("div", { staticClass: "news-item" }, [
            _vm._v("活动地址：" + _vm._s(_vm.item.addr))
          ]),
          _c("div", { staticClass: "news-item" }, [
            _vm._v("主办方：" + _vm._s(_vm.item.orgUnit_dictText))
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "introduce" }, [
      _vm._m(0),
      _c("div", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.item.introduce) }
      })
    ]),
    _vm.item.meaning
      ? _c("div", { staticClass: "sign" }, [
          _vm._m(1),
          _c("div", {
            staticClass: "content",
            domProps: { innerHTML: _vm._s(_vm.item.meaning) }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "text" }, [_vm._v("活动介绍")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("活动意义")]),
      _c("img", {
        staticClass: "img4",
        attrs: { src: require("@/assets/img/park/title_bg.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }