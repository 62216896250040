import { axios } from '@/utils/request'

/**
 * 新闻列表
 * @param {*} parameter
 * @returns
 */
export function listNews(parameter) {
  return axios({
    url: '/home/tbHomeNews/list',
    method: 'get',
    params: parameter
  })
}

export function getNewDetail(parameter) {
  return axios({
    url: '/home/tbHomeNews/queryById',
    method: 'get',
    params: parameter
  })
}

// 活动列表
export function listActivity(parameter) {
  return axios({
    url: '/org/tbActivity/public/list',
    method: 'get',
    params: parameter
  })
}

// 活动详情
export function getActivityDetail(parameter) {
  return axios({
    url: '/org/tbActivity/public/queryById',
    method: 'get',
    params: parameter
  })
}

// 入驻流程
export function entryProcessDetal(parameter) {
  return axios({
    url: '/project/tbEntryProcess/public/list',
    method: 'get',
    params: parameter
  })
}
